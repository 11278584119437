@import "../../_globalColor";

.social-media-div {
  font-size: 2em;
}

.icon-button {
  margin-bottom: 10px;
  color: $textColorDark;
  border-radius: 2.6rem;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  width: 2.6rem;
  margin-bottom: 10px;
  transition: 0.2s ease-in;
}

/* Hover Transition */
.icon-button:hover {
  background-color: $textColor;
}

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
  }
}
